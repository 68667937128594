import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { Dialog, Button } from "@urbaninfrastructure/react-ui-kit";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "dialog"
    }}>{`Dialog`}</h1>
    <p>{`Checkbox component which keeps its own state. To keep your own state, use CheckboxStateless`}</p>
    <h2 {...{
      "id": "default"
    }}>{`Default`}</h2>
    <Playground __position={0} __code={'<Dialog\n  heading=\"Dialog heading\"\n  renderOpenButton={openPortal => (\n    <Button onClick={openPortal}>Open Portal</Button>\n  )}\n>\n  This is an example dialog where information or forms may be presented. To\n  close the dialog press ESC, click outside, or click the close button\n</Dialog>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Dialog,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Dialog heading="Dialog heading" renderOpenButton={openPortal => <Button onClick={openPortal} mdxType="Button">Open Portal</Button>} mdxType="Dialog">
    This is an example dialog where information or forms may be presented. To
    close the dialog press ESC, click outside, or click the close button
  </Dialog>
    </Playground>
    <h2 {...{
      "id": "with-children-as-function"
    }}>{`With children as function`}</h2>
    <Playground __position={1} __code={'<Dialog\n  heading=\"Dialog heading\"\n  renderOpenButton={openPortal => (\n    <Button onClick={openPortal}>Open dialog</Button>\n  )}\n>\n  {({ closePortal }) => {\n    return <Button onClick={closePortal}>Close dialog</Button>\n  }}\n</Dialog>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Dialog,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Dialog heading="Dialog heading" renderOpenButton={openPortal => <Button onClick={openPortal} mdxType="Button">Open dialog</Button>} mdxType="Dialog">
    {({
          closePortal
        }) => {
          return <Button onClick={closePortal} mdxType="Button">Close dialog</Button>;
        }}
  </Dialog>
    </Playground>
    <h2 {...{
      "id": "component-props"
    }}>{`Component props`}</h2>
    <Props of={Dialog} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      